<template>
  <DynamicForm
    :schema="schema"
    :button-data="submitButton"
    :is-disabled="false"
    :initial-field-value="initialFieldValue"
    form-class="justify-content-start align-items-end"
    @submit="submit"
    :onChangeHandler="onChangeHandler"
    :resetButton="resetButton"
    :needPrevalidation="!!needPrevalidation"
    :reset-disabled="resetDisabled"
    :submitAlwaysEnabled="true"
  ></DynamicForm>
</template>

<script>
import DynamicForm from './DynamicForm.vue'
import 'v-calendar/dist/style.css'

export default {
  name: 'SingleMessageForm',
  components: {
    DynamicForm,
  },

  props: {
    schema: {
      type: Array,
      required: true,
    },
    submitButton: {
      type: Object,
      required: true,
    },
    initialFieldValue: {
      type: Object,
      required: true,
    },
    submit: {
      required: true,
    },
    onChangeHandler: {
      type: Function,
      required: true,
    },
    resetButton: {
      type: Object,
      required: true,
    },
    needPrevalidation: {
      type: Boolean,
      required: false,
    },
    resetDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>
